var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-data-iterator"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pt-0"},[_c('table-filters',{attrs:{"fields":_vm.headers,"list-filters":_vm.listFilters},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)],1),_c('overlay',{attrs:{"show":_vm.busy}},[_c('d-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"item-class":_vm.itemClass,"action-width":70,"row-action":""},scopedSlots:_vm._u([{key:"item.restaurant",fn:function(ref){
var item = ref.item;
return [_c('tablet-restaurant',{staticClass:"py-1",attrs:{"value":item}})]}},{key:"item.app_status",fn:function(ref){
var item = ref.item;
return [_c('tablet-app-status',{staticClass:"py-1",attrs:{"value":item}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('tablet-status',{staticClass:"py-1",attrs:{"value":item}})]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('tablet-user',{staticClass:"py-1",attrs:{"value":item}})]}},{key:"item.auto_accept_enabled",fn:function(ref){
var item = ref.item;
return [_c('tablet-auto-accept',{attrs:{"value":item}})]}},{key:"item.pages",fn:function(ref){
var item = ref.item;
return [_c('tablet-nr-input',{attrs:{"propkey":"pages","min":"0","value":item}})]}},{key:"item.volume",fn:function(ref){
var item = ref.item;
return [_c('tablet-nr-input',{attrs:{"propkey":"volume","min":"0","max":"10","value":item}})]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('tablet-note',{attrs:{"value":item}})]}},{key:"rowActions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","target":"","icon":"","color":"brandAlt"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item-group',[_c('v-list-item',{on:{"click":function($event){_vm.itemToNoteEdit = item}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t('tablets.actions.edit_note'))+" ")])],1),(item.sn)?_c('v-list-item',{on:{"click":function($event){_vm.itemToReboot = item}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("rotate_left")])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t('tablets.actions.rebooot'))+" ")])],1):_vm._e(),(item.sn)?_c('v-list-item',{on:{"click":function($event){_vm.itemToMessage = item}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("message")])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t('tablets.actions.send_msg'))+" ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){_vm.itemToDelete = item.id}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t('tablets.actions.delete'))+" ")])],1)],1)],1)],1)]}}],null,true)})],1),_c('confirm-modal',{attrs:{"value":!!_vm.itemToDelete},on:{"onCancel":function($event){_vm.itemToDelete = null},"onApprove":function($event){return _vm.deleteItem(_vm.itemToDelete)}}}),_c('confirm-modal',{attrs:{"value":!!_vm.itemToReboot},on:{"onCancel":function($event){_vm.itemToReboot = null},"onApprove":function($event){return _vm.rebootItem(_vm.itemToReboot)}}}),_c('note-modal',{attrs:{"value":_vm.itemToNoteEdit},on:{"close":function($event){_vm.itemToNoteEdit = null}}}),_c('message-modal',{attrs:{"value":_vm.itemToMessage},on:{"close":function($event){_vm.itemToMessage = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }