import apiClient from '@/api/ApiClient'
import ResponseHandler from '@/api/response/ResponseHandler'

class SunmiApiClient {
    baseUrl = '/api/v1/sunmi'

    reboot(serialNumber) {
        return ResponseHandler.handle(
            apiClient.post(`${this.baseUrl}/reboot`, {
                serialNumber,
            })
        )
    }

    message(serialNumber, title, message) {
        return ResponseHandler.handle(
            apiClient.post(`${this.baseUrl}/message`, {
                serialNumber,
                title,
                message,
            })
        )
    }
}

export default new SunmiApiClient()
